<template>
	<div :id="`disclosure-${id}`" class="slidedown-disclosure">
		<Disclosure
			v-slot="{ open }"
			ref="disclosureRef"
			:default-open="defaultOpen"
		>
			<template v-if="!disclosureButtonAtBottom">
				<DisclosureButton
					ref="disclosureButtonRef"
					class="flex w-full rounded-t"
					:class="[open ? 'opened' : 'closed']"
					:data-state="open ? 'opened' : 'closed'"
					@click="onDisclosureButtonClick(open)"
					@close-disclosure="close"
				>
					<slot name="disclosure-button" :open="open">
						<span :class="buttonClass">
							{{ open ? openedLabel : closedLabel }}
						</span>
					</slot>
				</DisclosureButton>
			</template>

			<Transition
				v-if="true"
				name="expand"
				@enter="enter"
				@after-enter="afterEnter"
				@leave="leave"
			>
				<DisclosurePanel>
					<slot name="disclosure-panel" :open="open">
						<div style="height: 250px" class="bg-gray-50"></div>
					</slot>
				</DisclosurePanel>
			</Transition>

			<template v-if="disclosureButtonAtBottom">
				<DisclosureButton
					ref="disclosureButtonRef"
					class="flex w-full"
					:class="[open ? 'opened' : 'closed']"
					:data-state="open ? 'opened' : 'closed'"
					@click="onDisclosureButtonClick(open)"
					@close-disclosure="close"
				>
					<slot name="disclosure-button" :open="open">
						<span :class="buttonClass">
							{{ open ? openedLabel : closedLabel }}
						</span>
					</slot>
				</DisclosureButton>
			</template>
		</Disclosure>
	</div>
</template>

<script setup>
import { ref } from 'vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

const props = defineProps({
	defaultOpen: {
		type: Boolean,
		default: false,
		required: false,
	},
	disclosureButtonAtBottom: {
		type: Boolean,
		default: false,
		required: false,
	},
	closedLabel: {
		type: String,
		default: 'Click me to the open the content',
		required: false,
	},
	openedLabel: {
		type: String,
		default: 'Click me to the close the content',
		required: false,
	},
	duration: {
		type: Number,
		default: 0.3,
		required: false,
	},
	buttonClass: {
		type: String,
		default: '',
		required: false,
	},
})

const emit = defineEmits(['beforeToggle'])

const disclosureRef = ref(null)
const disclosureButtonRef = ref(null)
const isOpen = ref(false)

const id = useId()

const enter = (element) => {
	const { width } = getComputedStyle(element)

	element.style.width = width
	element.style.position = 'absolute'
	element.style.visibility = 'hidden'
	element.style.height = 'auto'

	const { height } = getComputedStyle(element)

	element.style.width = null
	element.style.position = null
	element.style.visibility = null
	element.style.height = 0

	// Force repaint to make sure the
	// animation is triggered correctly.

	getComputedStyle(element).height

	// Trigger the animation.
	// We use `requestAnimationFrame` because we need
	// to make sure the browser has finished
	// painting after setting the `height`
	// to `0` in the line above.
	requestAnimationFrame(() => {
		element.style.height = height
	})
}

const afterEnter = (element) => {
	element.style.height = 'auto'
}

const leave = (element) => {
	const { height } = getComputedStyle(element)

	element.style.height = height

	// Force repaint to make sure the
	// animation is triggered correctly.

	getComputedStyle(element).height

	requestAnimationFrame(() => {
		element.style.height = 0
	})
}

const onDisclosureButtonClick = (openState) => {
	isOpen.value = openState
	emit('beforeToggle', openState)
}

// ref - https://headlessui.com/vue/disclosure#closing-disclosures-manually
// unfortunately, headless UI doesn't provide a way close the disclosure from outside :| so have to do it ourselves
const close = () => {
	if (!disclosureButtonRef.value || !disclosureButtonRef.value.$el) return

	disclosureButtonRef.value.$el.click()
}

const openThis = () => {
	disclosureButtonRef.value.$el.click()
}

defineExpose({ close, openThis })
</script>
